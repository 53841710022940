.datePicker > div > div {
	z-index: 5;
}

.datePicker input {
	height: 2.5rem;
	width: 100%;
}

.container {
	display: flex;
	flex-wrap: wrap;
}

.container > div {
	width: 48.5%;
	padding-left: 10px;
}

.errorContainer {
	width: calc(97% - 10px);
	margin-left: 10px;
}

.book > * {
	width: calc(48.5% - 10px);
	margin: 10px;
}

.addBtn {
	padding: 0.6em 2em;
}

.newTodo {
	padding: 0.5em 0.75em;
}

.hover:hover {
	cursor: pointer;
}

/* mobile */
@media only screen and (max-width: 768px) {
	.container {
		flex-wrap: nowrap;
		flex-direction: column;
	}

	.container > div {
		width: 100%;
		padding: 0;
	}

	.errorContainer {
		width: 100%;
		margin: 0;
	}

	.book > * {
		width: 100%;
		margin: 20px 0;
	}
}
