.wrapper {
	display: flex;
	justify-content: space-between;
}
.buttons {
	flex: 1;
	margin-right: 10px;
	margin-bottom: 20px;
}
.results {
	flex: 2;
}
.results p {
	margin-top: 5px;
	color: var(--mainBlue);
}
.button {
	margin-bottom: 10px;
	cursor: pointer;
	min-width: 220px;
}

@media only screen and (max-width: 1024px) {
	.button {
		min-width: 200px;
	}
}
@media only screen and (max-width: 768px) {
	.wrapper {
		display: block;
	}
	.button {
		min-width: 160px;
	}
}
