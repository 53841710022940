.content {
	display: flex;
}
.form {
	min-width: 200px;
	margin-right: 40px;
}
.credentials span {
	opacity: 0.7;
}
.form-input {
	border-radius: 5px;
}
.form label {
	margin-bottom: 10px;
}
.form input,
label {
	width: 100%;
}
.form div:last-child {
	width: 50%;
	margin-left: auto;
	text-align: right;
}
.form div:last-child a {
	font-size: 70%;
}
.form input {
	margin-bottom: 15px;
}
.login_btn,
.logout {
	width: 140px;
}
.login_btn,
.forgot_password {
	width: 100%;
	margin-bottom: 8px;
}
.error_message {
	color: var(--mainWhite);
	background-color: #e71d1d;
	padding: 5px 10px;
	border-radius: 5px;
	margin-bottom: 10px;
}
.success_lgn {
	margin-bottom: 10px;
}
@media only screen and (max-width: 1024px) {
	.form {
		margin-bottom: 20px;
	}
}
@media only screen and (max-width: 768px) {
	.content {
		display: block;
	}
	.form {
		margin-bottom: 0;
	}
	.form div:last-child a {
		font-size: 100%;
	}
	.form div:last-child {
		width: 100%;
		text-align: left;
	}
	.form {
		min-width: 0;
		margin-right: 0;
	}
}
