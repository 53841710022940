.items {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 20px;
}

.red_btn,
.blue_btn {
	min-width: 250px;
	display: flex;
	justify-content: flex-start;
	margin-right: 55px;
}

.red_box,
.blue_box {
	width: 60px;
	margin-right: 400px;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 4px;
}

@media only screen and (max-width: 1024px) {
	.red_btn,
	.blue_btn {
		min-width: 220px;
	}
	.red_box,
	.blue_box {
		width: 50px;
	}
}

@media only screen and (max-width: 768px) {
	.items {
		display: block;
	}
	.red_btn,
	.blue_btn {
		min-width: 200px;
	}
	.red_box,
	.blue_box {
		margin-right: 4px;
		margin-top: 20px;
		width: 20px;
	}
}
