.boxWrapper > p {
	color: var(--mainWhite);
	background: var(--mainBlue);
	width: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	padding: 10px;
}

@media only screen and (max-width: 1024px) {
	.boxWrapper > p {
		width: 100px;
	}
}

@media only screen and (max-width: 768px) {
	.boxWrapper > p {
		width: 80px;
	}
}
