/* 
	fullwidth: 100%
	innerwidth: 80%
*/

.homepage p {
	line-height: 2rem;
}
.homepage {
	height: 100%;
}
