.link {
	display: inline-block;
	background-color: var(--mainBlue);
	color: var(--mainWhite);
	margin-bottom: 10px;
	cursor: pointer;
	transition: 0.1s linear;

	width: 200px;
	border-radius: 5px;
	padding: 5px 10px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.link:hover {
	background-color: #1a51c7;
	transition-delay: 0.1s;
	color: var(--mainWhite);
}

@media only screen and (max-width: 1024px) {
	.link {
		width: 160px;
	}
}

@media only screen and (max-width: 768px) {
	.link {
		width: 120px;
	}
}
