.pagBody {
	display: flex;
	justify-content: space-between;
}
.pagBodyData {
	flex: 1;
	padding: 10px;
	background: var(--mainGray);
}
.pagBodyData > p {
	margin-bottom: 5px;
}
.pagBodyImage {
	flex: 2;
	width: 800px;
	height: 400px;
}
.pagBodyImage > img {
	width: 100%;
	height: 100%;
}
.pagControls {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
}
.pagControls > div {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	.pagBodyImage {
		width: 600px;
		height: 300px;
	}
}
@media only screen and (max-width: 768px) {
	.pagBody {
		display: block;
	}
	.pagBodyImage {
		width: 100%;
		height: 260px;
	}
}
