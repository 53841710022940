.header {
	width: 100%;
	background: var(--mainWhite);
	padding: 20px 0;
}
.header a {
	cursor: pointer;
}
.headerContainer {
	width: 80%;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.logo {
	width: 120px;
	height: auto;
	max-width: 200px;
	margin-left: -6px;
}
.logo img {
	height: 100% !important;
}
.logo:hover {
	cursor: pointer;
}
.menus {
	display: flex;
	align-items: center;
}
.menus > div:last-child {
	margin-left: 36px;
}
.menus > div:last-child a {
	color: #000;
}
.menus div:last-child a:hover {
	color: #243c8f;
}
.button {
	border: none;
	width: 160px;
	height: 40px;
	transition: ease-in-out 0.05s;
	color: #000;
}
.button span {
	margin-right: 10px;
}
.button:hover {
	color: #243c8f !important;
}
.dropdownContent {
	background: var(--mainWhite);
	min-width: 200px;
	padding: 0px 20px;
}
.dropdownContent > a {
	color: var(--mainTextColor);
	text-align: left !important;
}

.dropdownContent a:hover {
	border: none;
	background: var(--mainWhite);
}
.dropdownItemFlex {
	display: flex;
	gap: 15px;
	align-items: center;
	justify-content: center;
	align-items: center;
}
.dropdownItemNotFlex {
	text-align: left;
}
.mobileMenus {
	text-align: left;
}
.mobileMenus a {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	text-align: left;
	color: #081920;
}
.mobileMenus a:hover {
	color: #243c8f;
	background-color: #fff;
}
.mobileMenus button {
	padding: 12px 14px;
	border-radius: 4px;
	background-color: #243c8f;
	border: none;
	margin-top: 10px;
}
.mobileMenus button a {
	color: #fff;
}
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.navbar_menu {
	width: 100%;
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 9;
	min-height: 100vh;
	padding: 0 10%;
}

.burger_items {
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 0;
}

.burger_items a {
	font-size: 20px;
}

@media only screen and (max-width: 1024px) {
	.headerContainer {
		width: 85%;
	}
}

@media only screen and (max-width: 768px) {
	.logo {
		max-width: 170px;
	}
}
