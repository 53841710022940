.courseColumn {
	margin-right: 10px;
	height: 350px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.courseColumn h3 {
	font-size: 16px !important;
	color: #000 !important;
	font-weight: normal !important;
}
.courseColumn strong {
	color: #000 !important;
}
.courseColumn button,
.cardBottom button {
	border-radius: 0 !important;
}
.hasBorder {
	border: 1px solid;
	border-radius: 5px;
	margin-bottom: 5px;
	padding: 3px;
	display: flex;
	align-items: center;
	gap: 15px;
}

.hasBorder > img {
	border-radius: 5px;
	width: 75px;
	height: 50px;
}

/* mobile */
@media only screen and (max-width: 768px) {
	.courseColumn {
		margin-bottom: 40px;
		margin-right: 0px;
		height: 300px;
	}
}
