.programs {
	width: 80%;
	margin: 0 auto;
	padding-top: 60px;
	padding-bottom: 60px;
}
.program {
	display: flex;
	gap: 40px;
	padding: 40px;
	border-radius: 20px;
	box-shadow: 0px 0px 4px 0px #243c8f33;
}
.program:nth-child(2) {
	margin-top: 60px;
	margin-bottom: 60px;
}
.block {
	width: 50%;
}
.block h4 {
	font-family: Poppins;
	font-size: 38px !important;
	font-weight: 700 !important;
	line-height: 45px;
	color: #f4a837 !important;
}
.block p {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 400;
	line-height: 22.4px;
	margin: 20px 0;
}
.block button {
	background-color: var(--mainBlue);
	color: #fff;
	font-family: Poppins;
	font-size: 18px;
	font-weight: 400;
	line-height: 22px;
	border: none;
	padding: 8px 18px;
	border-radius: 8px;
}
.block img {
	width: 100%;
	object-fit: cover;
	height: 362px;
	border-radius: 16px;
}
.right {
	order: 1;
}
@media only screen and (max-width: 1024px) {
	.programs {
		padding-top: 40px;
		padding-bottom: 40px;
		width: 85%;
	}
	.program {
		flex-direction: column;
		padding: 20px;
	}
	.program:nth-child(2) {
		margin-top: 40px;
		margin-bottom: 40px;
	}
	.block:first-child {
		order: 2;
	}
	.block:nth-child(2) {
		order: 1;
	}

	.block {
		width: 100%;
	}

	.block h4 {
		font-size: 24px !important;
		line-height: 28px;
	}
	.block p {
		font-size: 12px;
		line-height: 20px;
	}

	.block button {
		font-size: 12px;
		line-height: 14px;
	}

	.block img {
		height: 320px;
	}

	.right {
		order: 0;
	}
}

@media only screen and (max-width: 768px) {
	.block img {
		height: 220px;
	}
}
