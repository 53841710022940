.subpageContentWrapper {
	background-color: var(--mainWhite);
	flex: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.subpageContent {
	min-height: 470px;
	margin: 0 auto;
}

.head {
	display: flex;
	justify-content: space-between;
}

.subpageContent,
.wrapper,
.content {
	height: 100%;
}

/* === override bulma .content class === */
.content {
	font-size: var(--textDesktopSize) !important;
	/* place-items: center; */
}

.pageHeaderContainer {
	background-color: var(--mainBlue);
	color: white;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 10% 20px 10%;
	margin-bottom: 2%;
}

.pageHeaderIcons {
	display: flex;
	align-items: center;
	gap: 20px;
}

.blueBg {
	background-color: var(--backgroundBlue);
}
.imageBg {
	background-image: url('./mock-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
}

/* tablet */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
	.content {
		font-size: var(--textTabletSize) !important;
	}
}

/* mobile */
@media only screen and (max-width: 768px) {
	.content {
		font-size: var(--textMobileSize) !important;
	}
}
