.react-dropdown-select {
	border: none !important;
	padding: 0 !important;
	min-height: 5px !important;
	font-size: var(--textDesktopSize) !important;
}
.react-dropdown-select input {
	border: none !important;
	padding: 0 !important;
}
.react-dropdown-select svg {
	opacity: 0.8;
}

@media only screen and (max-width: 1024px) {
	.react-dropdown-select {
		font-size: var(--textTabletSize) !important;
	}
}
@media only screen and (max-width: 768px) {
	.react-dropdown-select {
		font-size: var(--textMobileSize) !important;
	}
}
