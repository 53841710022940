.heroBanner {
	min-height: 720px;
	width: 100%;
}
.container {
	border-radius: 20px;
	width: 80%;
	height: 660px;
	margin: 0 auto 15px auto;
	background-image: url('./images/hero.jpeg');
	background-size: cover;
	background-repeat: no-repeat;
	background-color: rgba(0, 0, 0, 0.2);
	background-blend-mode: darken;
	background-position-x: 50%;
	padding: 60px;
}
.container > div:first-child {
	flex: 2;
}
.container > div:last-child {
	flex: 1;
}

.heading {
	width: 50%;
	font-family: Poppins;
	color: #ffffff;
}
.heading h1 {
	font-weight: 700 !important;
	font-size: 60px !important;
	line-height: 72px;
	text-wrap: balance;
}
.heading h1:nth-child(2) {
	margin-bottom: 20px;
}
.heading p {
	font-weight: 500;
	font-size: 18px;
	line-height: 23px;
}

.seeProgram {
	margin-top: 80px;
}

.btnLink {
	width: 200px;
	background-color: var(--mainBlue);
	padding: 12px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 8px;
	transition: 0.3s ease-in-out;
	margin-bottom: 14px;
}
.btnLink a,
.btnLink svg {
	color: var(--mainGray);
}
.btnLink a:hover {
	color: #fff;
}

.seeProgram > p {
	color: var(--mainGray) !important;
}

@media only screen and (max-width: 1024px) {
	.heroBanner {
		min-height: 660px;
	}
	.container {
		padding: 20px;
		height: 600px;
		display: flex;
		align-items: flex-end;
		background-position-x: 60%;
		width: 85%;
	}
	.heading {
		width: 60%;
	}
	.heading h1 {
		font-size: 26px !important;
		line-height: 33px;
	}
	.heading h1:nth-child(2) {
		margin-bottom: 20px;
	}
	.heading p {
		font-weight: 500;
		font-size: 12px;
		line-height: 20px;
	}
	.seeProgram {
		margin-top: 40px;
	}
	.btnLink {
		width: 178px;
		padding: 13px;
		margin-bottom: 5px;
	}
}

@media only screen and (max-width: 431px) {
	.heroBanner {
		min-height: 640px;
	}
	.heading {
		width: 80%;
	}
}
