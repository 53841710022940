.content {
	min-width: 300px;
	max-width: 30%;
}
.content label,
.email {
	width: 100%;
}
.email {
	margin-top: 10px;
	margin-bottom: 10px;
}
.content label {
	margin-bottom: 10px;
}
.btn_container {
	display: flex;
	align-content: flex-end;
	justify-content: flex-end;
	margin-top: 10px;
}
.submit_btn {
	width: 50%;
}

@media only screen and (max-width: 768px) {
	.btn_container {
		display: block;
	}
	.submit_btn {
		width: 100%;
	}
}
