.tableHeader {
	white-space: nowrap;
	/* border-collapse: cal(-20px); */
	background-color: var(--mainBlue) !important;
}
.th {
	color: var(--mainWhite) !important;
}
.th div:first-child {
	padding-bottom: 5px !important;
}
.sort {
	border-top: 1px solid var(--mainWhite);
	padding-top: 5px;
}

/* mobile */
/* @media only screen and (max-width: 768px) {

} */
