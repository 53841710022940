.colmn {
	display: flex;
	margin: 5px 0;
	align-items: center;
}
.wrapper button {
	min-width: 200px;
	text-align: left;
	margin-right: 20px;
}
.wrapper .colmn:nth-child(4) button:first-child {
	margin-bottom: 5px;
}
.wrapper input {
	min-width: 200px;
	padding: 4px;
}
@media only screen and (max-width: 768px) {
	.colmn {
		display: block;
	}
	.wrapper .colmn:nth-child(4) button:first-child {
		margin-bottom: 15px;
	}
	.colmn > p {
		padding: 5px 0;
	}
}
