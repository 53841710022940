.modal > section {
	border-radius: 0px 0px 6px 6px;
}

.button {
	float: right;
}

.tableHeaders {
	white-space: nowrap;
}
