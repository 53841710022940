.wrapper {
	font-size: 16px !important;
}
.select {
	border: 1px solid #ccc;
	border-radius: 5px;
	color: gray;
	font-size: inherit;
	padding: 5px 10px;
	width: 100%;
}
.select:last-child {
	padding: 0 0 0 5px !important;
}
.select:focus {
	outline: black;
}
.select input {
	color: gray;
	font-size: inherit;
	height: 100%;
}
.row {
	margin-bottom: 25px;
}
.row > p {
	margin-bottom: 5px;
}
