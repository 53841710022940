h2,
h3,
h4,
h5,
h6,
strong {
	color: var(--mainTextColor) !important;
}

pre * {
	background-color: #f5f5f5 !important;
}

svg {
	vertical-align: 0 !important;
}

@media screen and (max-width: 750px) {
	pre > div > code {
		padding: 1em !important;
	}
}
