.c_box {
	border: none;
	background-color: var(--mainBlue);
	color: var(--mainWhite);
	border-radius: 5px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: var(--textDesktopSize) !important;
	padding: 1px;

	/* lg */
	min-height: 40px; /* this is important is box doesn't have a content */
	min-width: 40px; /* this is important is box doesn't have a content */
	padding: 10px;
}

/* tablet */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
	.c_box {
		/* min-height: 40px; */
		font-size: var(--textTabletSize) !important;
		min-height: 35px; /* this is important is box doesn't have a content */
		min-width: 35px; /* this is important is box doesn't have a content */
	}
}

/* mobile */
@media only screen and (max-width: 768px) {
	.c_box {
		min-height: 25px; /* this is important is box doesn't have a content */
		min-width: 25px; /* this is important is box doesn't have a content */
		font-size: var(--textMobileSize) !important;
	}
}
