.mainContainer {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 30px;
	grid-auto-rows: minmax(100px, auto);
}
.mainContainer > div {
	border: 1px solid lightgray;
	padding: 15px;
	border-radius: 5px;
}
.mainContainer h3 {
	margin-bottom: 10px;
}

.mainContainer > div > h3 {
	border-bottom: 1px solid rgb(238, 238, 238);
}

.orderedList {
	list-style: none;
}

.buttonsContainer button {
	width: 75px;
	margin-right: 10px;
}

.buttonsContainer span {
	color: var(--mainBlue);
}

.dropdowns select {
	width: 175px;
	height: 30px;
}

/* mobile */
@media only screen and (max-width: 768px) {
	.mainContainer {
		display: flex;
		flex-direction: column;
	}

	.dateInputs {
		margin-top: 0;
	}
}
