.mockHeading {
	text-align: center;
	margin: 40px auto 25px;
	color: var(--mainBlue);
}

.mockContentBody {
	background-color: var(--mainWhite);
	color: var(--mainBlue);
	text-align: center;
	padding: 15px;
	border-radius: 5px;
}

.mockHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.mockHeader strong {
	color: var(--mainBlue) !important;
}

.mockInnerHeader {
	display: flex;
	justify-content: center;
}

.iTimer {
	border: 1px solid rgb(173, 183, 230);
	border-radius: 4px;
	padding: 5px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-width: 110px;
	gap: 10px;
	font-weight: 600;
}

.qIcon {
	border-radius: 100%;
	background-color: var(--mainWhite);
	height: 18px;
	width: 18px;
	color: var(--mainBlue);
	padding: 8px;
}

.mockQuestion {
	padding: 40px 0;
	text-align: center;
}

.mockQuestion h2 {
	margin-bottom: 24px;
	color: var(--mainBlue) !important;
	font-weight: 400 !important;
	font-size: 19px !important;
}
.mockQuestion p {
	margin-bottom: 15px;
}
.mockQuestion a {
	color: orange;
}
.mockQuestion ol {
	/* margin: auto 15px 15px; */
	padding: 15px;
	text-align: left;
}
.mockQuestion ol li {
	padding: 4px 0;
}

.mockQuestion img {
	width: 100%;
	max-height: 400px;
}
.mockQuestion pre {
	text-align: left;
	color: var(--mainBlue);
}

.btns,
.btns button {
	display: flex;
	align-items: center;
	gap: 15px;
}
.btns {
	justify-content: center;
}
.btns button {
	border: 1px solid rgba(138, 158, 224, 0.65);
	padding: 4px 12px;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.04s ease-in-out;
	justify-content: center;
}

.btns button:first-child {
	background-color: rgba(245, 247, 254, 1);
	color: var(--mainBlue);
}
.btns button:first-child:disabled {
	color: gray;
}
.btns button:last-child {
	background-color: var(--mainBlue);
	color: var(--mainWhite);
}

.btns button:hover {
	opacity: 0.9;
}

.questionContent {
	padding: 20px 10px;
}

.mockQuestion > div > div {
	padding: 0 !important;
}

.mockQuestion > div > div > pre {
	height: auto;
	border-radius: 4px;
}

.questionContent {
	padding: 20px 10px;
}
.codeArea {
	width: 100%;
	min-height: 300px;
	padding: 15px;
	resize: none;
	border-radius: 4px;
}
.codeArea pre {
	border-radius: 4px;
}

@media screen and (max-width: 768px) {
	.mockHeading {
		margin: 25px auto 15px;
		text-align: right;
	}

	.mockContentBody > section {
		padding: 20px;
	}

	.btns {
		padding-bottom: 20px;
	}

	.btns button {
		flex-basis: 50%;
	}

	.questionContent {
		padding: 10px 5px;
	}
}
