.footer {
	padding: 46px 0;
	background-color: var(--mainBlue);
	color: white;
	font-size: 16px;
}

.socials {
	display: flex;
	justify-content: space-between;
	height: 28px;
}
.container {
	height: 300px;
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 auto;
}

.footer .up {
	display: flex;
	justify-content: space-between;
}

.footer .left {
	width: 200px;
	height: 108px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.footer .right {
	width: 332px;
	height: 190px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.socials a :hover {
	transform: scale(1.3);
	transition: all 0.2s linear;
}

.links {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 115px;
}

.links ul {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.logo {
	width: 145px;
	height: auto;
	margin-left: -10px;
}

.footer .down {
	display: flex;
	justify-content: space-between;
	border-top: 0.4px solid #aebdff;
	padding-top: 30px;
}

.exercises {
	width: 140px;
}

/* no global css */
/* ul p {
	font-size: 26px;
	font-weight: bold;
} */

.button {
	width: 152px;
	color: var(--mainBlue) !important;
}

@media only screen and (max-width: 768px) {
	.footer {
		height: 514px;
		font-size: 14px;
		padding: 25px 0;
	}

	.container {
		height: 458px;
		width: 85%;
	}

	.footer .left {
		height: 70px;
	}

	/* no global css */
	/* ul > p {
		font-size: 18px;
	} */

	.footer .right {
		height: 250px;
	}

	.footer .links {
		height: 180px;
	}
	.footer .up {
		flex-direction: column;
	}

	.links {
		flex-direction: column;
		width: 130px;
	}

	.footer .down {
		flex-direction: column;
		height: 108px;
		align-items: center;
		justify-content: space-between;
		padding-top: 13px;
	}

	.links ul {
		height: 76px;
	}

	.logo {
		width: 92px;
		height: auto;
		margin-left: -5px;
	}

	.button {
		width: 130px;
		height: 40px;
		font-size: 12px;
	}

	.address,
	.copyright {
		height: 22px;
	}

	.socials {
		width: 162px;
	}
}
