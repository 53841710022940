.editorContainer {
	background: #fff;
	border-radius: 4px;
	margin-bottom: 15px;
}
.controls {
	text-align: right;
	background-color: var(--mainBlue);
	padding: 5px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}
.controls > button {
	background: #f4a837;
	border: none;
	border-radius: 4px;
	padding: 6px 25px;
	color: var(--mainBlue);
	font-weight: bold;
	cursor: pointer;
	transition: background-color 2s ease-out;
}
.controls > button:hover {
	color: #000;
}
.editorBox {
	display: flex;
	background: rgba(232, 237, 255, 1);
}
.cm-editor,
.cm-theme {
	background: rgba(232, 237, 255, 1) !important;
	background-color: black !important;
}
.logoBox {
	padding: 4px 7px;
	margin-right: 5px;
	border-right: 1px solid rgb(229, 232, 236);
}
.logoBox > div {
	border: 1px solid rgb(159, 159, 159);
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	margin-bottom: 5px;
	opacity: 0.5;
	cursor: pointer;
}
.logoBox img {
	width: 28px;
	height: auto;
	max-height: 28px;
}

.active {
	opacity: 1 !important;
	border: 1px solid var(--mainBlue) !important;
}
.codeMirror {
	text-align: left;
	flex: 1;
}
.output {
	flex: 1;
	border-top: 1px solid rgb(221, 223, 227);
	color: #000;
	text-align: left;
	padding: 10px;
	background: rgba(232, 237, 255, 1);
}
.output h4 {
	color: var(--mainBlue) !important;
}
.output pre {
	background-color: #fff;
	background: rgba(232, 237, 255, 1);
}

@media screen and (max-width: 768px) {
	.editorBox {
		display: block;
	}
	.logoBox {
		display: flex;
		gap: 10px;
	}
}
