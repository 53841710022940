@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root {
	/* colors */
	--mainGray: #e2e2e2;
	--darkGray: #787777;
	--codeGray: #f5f5f5;
	/* --mainBlue: #225ad7; */
	--mainBlue: #243c8f;
	--whiteSmoke: #f5f5f5;
	--mainWhite: #ffffff;
	--mainTextColor: #696969;
	--darkTextColor: #181818;
	--mainRed: #b50404;
	--mainGreen: #238c21;
	--darkBlue: #101c63;
	--lightBlue: #4560af;
	--backgroundBlue: #f3f5fe;

	/* typography */
	--mainFont: 'Poppins', Helvetica, Arial, sans-serif;

	/* font */
	/* --textDesktopSize: 1rem; */
	--textDesktopSize: 0.9rem;
	--textTabletSize: 0.9rem;
	--textMobileSize: 0.8rem;

	/* header - h1 */
	--h1desktop: 1.6rem;
	--h1tablet: 1.4rem;
	--h1mobile: 1.2rem;

	/* subheader - h2 */
	--h2desktop: 1.4rem;
	--h2tablet: 1.2rem;
	--h2mobile: 1rem;

	/* subsubheader - h3 */
	--h3desktop: 1.2rem;
	--h3tablet: 1rem;
	--h3mobile: 0.8rem;
	/*
	*/
}

*::before,
*::after {
	box-sizing: border-box;
}

html,
body,
#root {
	height: 100%;
	font-weight: normal;
	color: var(--mainTextColor);
}

body {
	margin: 0;
	font-family: var(--mainFont) !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: var(--textDesktopSize) !important;
}

/* === reset === */

input,
select {
	border: 1px solid rgba(36, 60, 143, 0.1) !important;
	border-radius: 5px !important;
	padding: 5px 10px !important;
}
.content,
button {
	font-size: var(--textDesktopSize);
	font-family: var(--mainFont) !important;
}
h1,
h2,
h3 {
	font-weight: 600 !important;
}
h1 {
	font-size: var(--h1desktop) !important;
}
h2 {
	font-size: var(--h2desktop) !important;
}
h3 {
	font-size: var(--h3desktop) !important;
}

/* tablet */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
	.content,
	button {
		font-size: var(--textTabletSize) !important;
	}
	h1 {
		font-size: var(--h1tablet) !important;
	}
	h2 {
		font-size: var(--h2tablet) !important;
	}
	h3 {
		font-size: var(--h3tablet) !important;
	}
}

/* mobile */
@media only screen and (max-width: 768px) {
	.content,
	button {
		font-size: var(--textMobileSize) !important;
	}
	h1 {
		font-size: var(--h1mobile) !important;
	}
	h2 {
		font-size: var(--h2mobile) !important;
	}
	h3 {
		font-size: var(--h3mobile) !important;
	}
}
