.loginPageWrapper {
	display: flex;
	justify-content: space-between;
	padding: 50px 0;
	gap: 20px;
}
.imageBox > img {
	height: 100%;
	width: 100%;
	max-height: 440px;
}
.loginForm {
	padding: 50px 70px;
	border-radius: 16px;
	background-color: #fff;
	font-family: Poppins;
	min-width: 500px;
}
.loginText {
	margin-bottom: 20px;
}
.loginText > h2 {
	color: var(--mainBlue) !important;
	font-size: 16px !important;
	line-height: 30px;
	font-weight: 600 !important;
}
.inputOuterDiv {
	margin-bottom: 20px;
}
.inputOuterDiv > input,
.inputOuterDiv > select {
	width: 100%;
	padding: 10px !important;
	color: rgba(8, 25, 32, 0.5);
	background: rgba(36, 60, 143, 0.04);
}
.loginForm label {
	color: rgba(8, 25, 32, 0.5);
}

.buttonOuterDiv > button {
	background: var(--mainBlue);
	color: #fff;
	border: 5px;
	border-radius: 5px;
	text-transform: uppercase;
	min-width: 120px;
	width: 100%;
	cursor: pointer;
	padding: 10px !important;
}
.errorMessage > p {
	color: rgb(251, 83, 83);
	margin-bottom: 10px;
}

@media screen and (max-width: 1200px) {
	.loginForm {
		padding: 40px 26px;
		min-width: 300px;
	}
}

@media screen and (max-width: 820px) {
	.loginPageWrapper {
		flex-direction: column;
		padding: 20px 0;
	}
	.imageBox {
		margin-bottom: 40px;
	}
	.imageBox > img {
		max-height: 440px;
	}
}
