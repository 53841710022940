.c_button {
	cursor: pointer;
	border: none;
	background-color: var(--mainBlue);
	color: var(--mainWhite);
	border-radius: 5px;
	transition: 0.1s linear;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: var(--textDesktopSize) !important;

	/* lg */
	/* min-width: 250px; */
	/* min-height: 40px; */
	padding: 5px 10px;
}
.c_button:hover {
	background-color: #1a51c7;
	transition-delay: 0.1s;
}
.c_button:disabled {
	background-color: darkgray;
}
.c_button:disabled:hover {
	background-color: darkgray;
}

/* tablet */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
	.c_button {
		font-size: var(--textTabletSize) !important;
	}
}

/* mobile */
@media only screen and (max-width: 768px) {
	.c_button {
		font-size: var(--textMobileSize) !important;
	}
}
