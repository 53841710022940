.accordionItem {
	padding-top: 0.5rem;
	color: var(--mainWhite) !important;
}

.accordionTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	background-color: var(--mainBlue);
	border-radius: 5px;
	padding: 0.5rem;
}

.accordionTitle:hover {
	background-color: #1a51c7;
}

.title {
	flex: 2 2 80%;
	text-shadow: 0 0 2px rgb(0, 0, 0);
}
.difficultyWrapper {
	font-size: 60%;
	font-weight: 600;
	flex: 1 1 15%;
	margin: 0 5px;
}

.arrow {
	flex: 1 1 auto;
	text-align: right;
}
.accordionContent {
	background-color: var(--codeGray);
	color: var(--darkTextColor);
}

@media screen and (max-width: 700px) {
	body {
		font-size: 18px;
	}

	.accordion {
		width: 100%;
	}
}
