.mission {
	background-color: var(--mainBlue);
	/* height: 588px; */
	padding: 80px 0;
}
.cardBox {
	display: flex;
	gap: 18px;
	width: 80%;
	margin: 0 auto;
	background-color: inherit;
}
.cardBox > div:first-child,
.cardBox > div:nth-child(2) {
	border-right: 0.2px solid;
}
.title {
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 18px;
}
.title h2 {
	color: var(--mainWhite) !important;
	font-size: 30px !important;
	font-weight: 500 !important;
	line-height: 36px;
	font-family: Poppins;
}
.cardBox p {
	color: var(--mainGray);
	font-size: 20px;
	font-weight: 400;
	line-height: 24px;
	font-family: Poppins;
}
.cardBox > div:first-child {
	flex: 1;
}
.cardBox > div:nth-child(2) {
	flex: 1;
}

.cardBox div:last-child {
	flex: 1;
}

@media only screen and (max-width: 1024px) {
	.cardBox {
		display: block;
		width: 85%;
	}
	.title h2 {
		font-size: 20px !important;
		line-height: 24px;
	}
	.cardBox p {
		font-size: 14px;
		line-height: 19px;
	}
	.cardBox div:nth-child(2) {
		margin: 80px 0;
	}
	.cardBox > div:first-child,
	.cardBox > div:nth-child(2) {
		border-right: none;
	}
	.title img {
		height: 42px;
		width: 42px;
	}
}
